body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root,
body,
html{
  height: 100%;
  width: 100%;
  font-size: 14px;
}

.text-primary-color {
  color: #5388ad !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-left: 4px;
  margin-bottom: auto;
  color: #ff5949;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(236, 236, 236);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5388ad;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5388ad;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
  background-color: #5388ad;
}

::-webkit-scrollbar-track:horizontal {
  box-shadow: inset 0 0 5px rgb(236, 236, 236);
  border-radius: 10px;
}

.ant-modal {
  border-radius: 10px;
  border: 1px solid white;
}

.ant-modal-header {
  background: transparent !important;
}

.ant-modal-content {
  background-image: url(./Assests/Images/layoutbacground.png);
  /* background: linear-gradient(157deg, rgba(255, 255, 255, 0.765) 30%, rgba(255, 255, 255, 0.388) 100%) !important; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-size: 100% 100%;
}

.ant-input {
  background: transparent;
}

.ant-input-affix-wrapper, .ant-select:not(.ant-select-customize-input) .ant-select-selector  {
  background: #ffffff9e ;
  border-color: white !important;
}

.ant-select-selection-item {
  line-height: 2.5 !important;
}

.ant-select-selection-overflow {
  height: auto !important;
}

.ant-select-selection-overflow-item {
  height: 40px !important;
}

:where(.css-14wwjjs).ant-select-multiple .ant-select-selection-item-content {
  margin-inline-end: 8px !important;
}

:where(.css-14wwjjs).ant-select-multiple .ant-select-selection-item {
  height: 36px !important;
}

.multiSelect .ant-select-selector {
  height: auto !important;
}

:where(.css-14wwjjs).ant-select-multiple .ant-select-selection-search-input, :where(.css-14wwjjs).ant-select-multiple .ant-select-selection-search-mirror {
  height: 42px;
}

:where(.css-14wwjjs).ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 42px !important;
}

:where(.css-14wwjjs).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 42px !important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-select-multiple .ant-select-selection-item-content {
  margin-inline-end: 8px;
  line-height: 2.5 !important;
}

.ant-select-show-search:where(.css-dev-only-do-not-override-14wwjjs).ant-select:not(.ant-select-customize-input) .ant-select-selector,:where(.css-dev-only-do-not-override-14wwjjs).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 44px;
  font-size: 16px;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-select .ant-select-clear {
  background-color: transparent;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 42px;
  transition: all 0.3s,visibility 0s;
}

.ant-form-item .ant-form-item-label >label {
  font-size: 16px;
}