.ant-modal-title {
    font-size: 20px !important;
    font-weight: bold !important;
}

.view-card-title {
    font-size: 20px !important;
    font-weight: 700 !important;
}

.view-btn {
    width: 34px;
    height: 34px;
    padding: 4px 4px;
    margin-left: 16px;
}

.view-btn:hover {    
    border-color: #5388ad;
}

.view-btn:hover svg {    
    color: #5388ad;
}