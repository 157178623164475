.layout-bg {
  background-image: url('../Assests/Images/layoutbacground.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-size: 100% 100%;
}

.app-title {
  font-size: 28px !important;
}

.text-shadow {
  text-shadow: 0px 4px 5px #0000001f;
}

.box-shadow {
  box-shadow: 0px 4px 5px #0000001f;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.ant-avatar {
  width: 42px;
  height: 42px;
}

.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus {
  border: 1px solid #ffffffed !important;
}

/* .ant-input-group-addon {
  display: none !important;
} */

.ant-input {
  height: 38px;
  font-size: 16px !important;
}

.ant-btn-primary {
  height: 46px !important;
  border-radius: 8px;
  background-color: white;
  border: 1.5px solid #fff;
  color: #5388ad;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 1px 1px 6px #0000001f;
}

.ant-btn .anticon svg {
  margin-bottom: 4px !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: transparent !important;
  background: linear-gradient(157deg, rgba(255, 255, 255, 0.721) 30%, rgba(255, 255, 255, 0.407) 100%) !important;
  color: #5388ad !important;
}

.project-icon {
  color: #7f7f7f;
}

.llr-card {
  background: linear-gradient(157deg, rgba(255, 255, 255, 0.765) 30%, rgba(255, 255, 255, 0.388) 100%) !important;
  border: 1px solid #fff;
  padding: 16px;
  border-radius: 12px;
  color: #3B3F46;
  cursor: pointer;
}

.pointer {
  cursor: pointer !important;
}

.llr-card:hover {
  background: #fff !important;
  border: 1.5px solid #5388ad;
  color: #5388ad;
  box-shadow: 0px 0px 8px #5387adbf;
}

.llr-card:hover .project-icon {
  color: #5388ad;
}

.llr-card-title {
  font-size: 18px !important;
  font-weight: bold;
}

.llr-card-info {
  font-size: 16px !important;
}

.llr-card-fw {
  font-weight: 600;
  font-size: 16px;
}

.ant-tag {
  height: 28px;
  border-color: #A2C2DA;
  color: #5388ad;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.ant-tooltip-inner {
  color: #5388ad !important;
  font-weight: 600;
}

.ant-spin-nested-loading {
  width: 100% !important;
}

.ant-spin-nested-loading >div>.ant-spin {
  max-height: 100%;
}

.ant-input-search :where(.css-dev-only-do-not-override-14wwjjs).ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-start-start-radius: 10px;
  border-end-start-radius: 10px;
}

.ant-input-group-addon button {
  height: 48px !important;
}

.ant-input-suffix .ant-input-clear-icon .anticon-close-circle svg{
  width: 14px !important;
  height: 14px !important;
  margin-bottom: 3px !important;
}

.ant-select-clear .anticon-close-circle svg{
  width: 14px !important;
  height: 14px !important;
  margin-bottom: 3px !important;
}

h5 {
  -webkit-font-smoothing: auto;
}

.ant-select-show-search:where(.css-dev-only-do-not-override-14wwjjs).ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  height: 44px !important;
}
:where(.css-14wwjjs).ant-select-single .ant-select-selector {
  font-size: 16px !important;
}

.ant-spin-nested-loading {
  height: 100% !important;
}

.ant-layout-footer {
  background-color: transparent !important;
}

.anticon-left svg {
  margin-bottom: 5px !important;
}

.anticon-right svg {
  margin-bottom: 5px !important;
}